


import React from 'react';
import RajyotsavaDay from 'views/RajyotsavaDay';

const RentalPage = () => {
  return <RajyotsavaDay />;
};

export default RentalPage;